/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo } from 'react';
import type { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Alert } from '@pulse-web-ui/alert';
import { Button } from '@pulse-web-ui/button';
import { Checkbox } from '@pulse-web-ui/checkbox';
import { DaDataFio, DaDataSuggestion } from '@pulse-web-ui/dadata';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Age } from '@pulse-web-ui/icons';

import { SectionContainer } from '@src/common-components/container';
import { BoldText, PreLineText } from '@src/common-components/headers';
import {
  FilterParts,
  FullWidthWrapper,
  MiddleNameWrapper,
  SiteAgreements,
  Skeleton,
  StyledDadataFioSuggestions,
  StyledLink,
} from '@src/components';
import { CalendarContainer } from '@src/components/container';
import {
  ClientCategory,
  FlatSteps,
  HouseSteps,
  Product,
  USER_PERSONAL_DATA_AGREEMENTS,
  USER_RECL_AGREEMENTS_URL,
} from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import {
  useHandlePressKey,
  useRegistrationRequest,
  useRequest,
} from '@src/hooks';
import { BaseLayout } from '@src/layouts/base-layout';
import { personalInfoSchema, updateProfileInfoSchema } from '@src/schemas';
import {
  PersonalInfoDataProps,
  Store,
  UserActionTypes,
  WizardActionTypes,
} from '@src/store';
import { KeyCode, UserPolice, UserRegistrationDataTypes } from '@src/types';
import {
  addTestAttribute,
  sendFlatProgress,
  sendHouseProgress,
} from '@src/utils';

import {
  AuthPageTitle,
  AuthPageWrapper,
  AuthSub,
  StyledAdaptiveColumns,
  StyledCheckboxContainer,
  StyledDatePicker,
  StyledFooter,
  StyledMessage,
} from './authorization-pages.styles';
import { usePersonalDataConsentVersion } from './hooks/use-personal-data-consent-version';
import { getAuthSubtitle } from './utils';

const adultDate = new Date();
adultDate.setFullYear(adultDate.getFullYear() - 18);
adultDate.setDate(adultDate.getDate() - 1);

const maxDate: Date | null | undefined = adultDate;
const userPoliciesCode002 = '002';
const userPoliciesCode003 = '003';
const userPoliciesCode005 = '005';
const userPolicies = new Set<string>();

const getDadataValue = (value: string | undefined) =>
  ({ value: value || '' } as DaDataSuggestion<DaDataFio>);

export const PersonalInfoPage: FC = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateUser: {
        regUtm,
        selectedProduct,
        cachedPersonalInfoData,
        isPureSubscription,
        profile,
        userRegistrationData: storeUserRegistrationData,
        agentLogin,
      },
      stateAuth: { authTokens },
      stateAuthFlow: { authFlowParams },
    },
    dispatch,
  } = useContext(Store);
  const { state: locationState } = useLocation();
  const authSubtitle = getAuthSubtitle(selectedProduct);
  const { isPDConsentAlternativeVersion, isFeatureFlagsLoading } =
    usePersonalDataConsentVersion(locationState?.isUpdateProfile);

  const defaultValues = useMemo(
    () => ({
      lastName: !!locationState?.isUpdateProfile
        ? profile?.profile?.lastName || ''
        : cachedPersonalInfoData?.lastName || '',
      firstName: !!locationState?.isUpdateProfile
        ? profile?.profile?.firstName || ''
        : cachedPersonalInfoData?.firstName || '',
      middleName: !!locationState?.isUpdateProfile
        ? profile?.profile?.middleName || ''
        : cachedPersonalInfoData?.middleName || '',
      useMiddleName:
        !!locationState?.isUpdateProfile && !profile?.profile?.middleName
          ? true
          : cachedPersonalInfoData?.useMiddleName || false,
      userPoliciesCode003:
        isPDConsentAlternativeVersion ||
        cachedPersonalInfoData?.userPoliciesCode003 ||
        false,
      userPoliciesCode002: cachedPersonalInfoData?.userPoliciesCode002 || false,
      birthDay: cachedPersonalInfoData?.birthDay || undefined,
    }),
    [
      cachedPersonalInfoData,
      isPDConsentAlternativeVersion,
      locationState?.isUpdateProfile,
      profile?.profile,
    ]
  );
  const extendedIntermediaryChannelCode = `${regUtm?.media_source ?? '0000'},${
    regUtm?.campaign ?? 'WEB'
  }`;

  const navigate = useNavigate();

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm<PersonalInfoDataProps>({
    resolver: yupResolver(
      !!locationState?.isUpdateProfile
        ? updateProfileInfoSchema
        : personalInfoSchema
    ),
    shouldFocusError: true,
    mode: 'all',
    defaultValues,
  });

  const birthDay = getValues('birthDay');
  const passportHint =
    !cachedPersonalInfoData?.useMiddleName &&
    selectedProduct !== Product.PETS_TELEVET_PLUS &&
    t('COMMON:labels.specifyAsPassport');
  const middleNameFieldHint = errors?.middleName?.message
    ? t(errors.middleName.message)
    : passportHint;

  const { isRegistrationLoading, registrationError, registrationRefetch } =
    useRegistrationRequest([storeUserRegistrationData]);

  let userRegistrationData: UserRegistrationDataTypes = {
    extendedIntermediaryChannelCode: extendedIntermediaryChannelCode,
    firstName: cachedPersonalInfoData?.firstName || '',
    lastName: cachedPersonalInfoData?.lastName || '',
    middleName: cachedPersonalInfoData?.middleName || '',
    birthDay:
      cachedPersonalInfoData?.birthDay &&
      format(new Date(cachedPersonalInfoData.birthDay), 'yyyy-MM-dd'),
    clientCategory: ClientCategory.STANDART,
  };

  if (regUtm?.utm_source) {
    userRegistrationData.utmSource = regUtm.utm_source;
  }

  if (regUtm?.utm_campaign) {
    userRegistrationData.utmCampaign = regUtm.utm_campaign;
  }

  if (regUtm?.utm_medium) {
    userRegistrationData.utmMedium = regUtm.utm_medium;
  }

  if (regUtm?.utm_content) {
    userRegistrationData.utmContent = regUtm.utm_content;
  }

  if (regUtm?.wm_id) {
    userRegistrationData.wmId = regUtm.wm_id;
  }

  useEffect(() => {
    if (profile?.profile) {
      const { firstName, lastName, middleName } = profile?.profile;

      if (!!firstName || !!lastName || !!middleName) {
        reset({
          firstName: firstName || '',
          lastName: lastName || '',
          middleName: middleName || '',
          ...(!!locationState?.isUpdateProfile && !middleName
            ? { useMiddleName: true }
            : {}),
        });
      }
    }
  }, [
    profile?.profile.firstName,
    profile?.profile.lastName,
    profile?.profile.middleName,
  ]);

  useEffect(() => {
    if (agentLogin || isPDConsentAlternativeVersion) {
      setValue('userPoliciesCode003', true);
    }
  }, [isPDConsentAlternativeVersion]);

  useEffect(() => {
    const input = document.getElementsByTagName('input')[0];
    input && !input.value && input.focus();

    if (
      locationState?.isUpdateProfile &&
      authFlowParams?.partnerAuthRegType !== 'base' &&
      birthDay
    ) {
      updateProfile();
    }
  }, []);

  const {
    error: updateProfileError,
    refetch: updateProfileRefetch,
    isLoading: updateProfileLoading,
    res: updateProfileRes,
  } = useRequest(
    'updateProfileRequest',
    'post',
    '/v1/user/update-profile',
    {
      clientChange: {
        birthDate: birthDay && format(new Date(birthDay), 'yyyy-MM-dd'),
      },
    },
    [birthDay],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (
      storeUserRegistrationData &&
      userRegistrationData &&
      isValid &&
      !isPureSubscription &&
      !locationState?.isUpdateProfile
    ) {
      const handler = setTimeout(() => {
        if (!errors.userPoliciesCode003) {
          registrationRefetch().then(({ status }) => {
            if (status === 'success') {
              dispatch({
                type: UserActionTypes.SetIsScoringSuccess,
                payload: false,
              });
              navigate(locationState?.nextRoute || '/login');
            }
          });
        }
      }, 100);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [
    storeUserRegistrationData,
    isValid,
    isPureSubscription,
    locationState?.isUpdateProfile,
    errors,
  ]);

  const updateProfile = () => {
    updateProfileRefetch().then((data) => {
      if (data.error?.response?.data?.code === 'TECHNICAL_ERROR') {
        return;
      }
    });
  };

  useEffect(() => {
    if (!updateProfileLoading && updateProfileRes) {
      dispatch({
        type: UserActionTypes.SetProfile,
        payload: updateProfileRes,
      });

      if (locationState?.nextStep) {
        dispatch({
          type: WizardActionTypes.SetCurrentStep,
          payload: locationState.nextStep,
        });
        !!locationState?.nextRoute
          ? navigate(locationState.nextRoute)
          : navigate(`/${selectedProduct}`);
      } else if (locationState?.nextRoute) {
        navigate(locationState.nextRoute);
      } else {
        navigate('/order-detail');
      }
    }
  }, [updateProfileLoading, updateProfileRes]);

  const handleRefetch = () => {
    if (registrationError) {
      registrationRefetch().then(({ status }) => {
        if (status === 'success') {
          dispatch({
            type: UserActionTypes.SetIsScoringSuccess,
            payload: false,
          });
          navigate(locationState?.nextRoute || '/login');
        }
      });
    }

    if (updateProfileError) updateProfile();
  };

  const submitPage = handleSubmit(async (value) => {
    const userPoliciesArr: UserPolice[] = [];

    if (
      isValid &&
      !locationState?.isUpdateProfile &&
      !errors.userPoliciesCode003
    ) {
      const approvedAt = new Date().toISOString();

      if (
        isPDConsentAlternativeVersion ||
        cachedPersonalInfoData?.userPoliciesCode003
      ) {
        userPolicies.add(userPoliciesCode003);
        userPolicies.add(userPoliciesCode005);
      } else {
        userPolicies.delete(userPoliciesCode003);
        userPolicies.delete(userPoliciesCode005);
      }

      userPolicies.forEach((code: string) => {
        userPoliciesArr.push({
          approvedAt,
          code,
        });
      });
    }
    userRegistrationData = {
      ...userRegistrationData,
      ...(!!userPoliciesArr.length && { userPolicies: userPoliciesArr }),
      lastName: value.lastName?.trim(),
      firstName: value.firstName?.trim(),
      middleName: value.useMiddleName ? '' : value.middleName?.trim(),
      birthDay:
        value.birthDay && format(new Date(value.birthDay), 'yyyy-MM-dd'),
    };

    dispatch({
      type: UserActionTypes.SetUserRegistrationData,
      payload: userRegistrationData,
    });

    if (locationState?.isUpdateProfile) {
      updateProfile();
    } else {
      if (isPureSubscription) {
        navigate('/login');
      }
    }
  });

  // TODO: Найти более подходящее решение для переключения по инпутам
  const handleKeyPressEnter = () => {
    const { firstName, lastName, middleName, birthDay } = getValues();
    if (
      firstName &&
      lastName &&
      (middleName || cachedPersonalInfoData?.useMiddleName) &&
      birthDay
    ) {
      submitPage();
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [submitPage]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (value.userPoliciesCode002) {
        userPolicies.add(userPoliciesCode002);
      } else {
        userPolicies.delete(userPoliciesCode002);
      }

      dispatch({
        type: UserActionTypes.SetCachedPersonalInfoData,
        payload: {
          ...value,
          lastName: value.lastName?.trim(),
          firstName: value.firstName?.trim(),
          middleName: value.useMiddleName ? '' : value.middleName?.trim(),
        },
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    switch (selectedProduct) {
      case Product.APARTMENT:
        sendFlatProgress(FlatSteps.AUTH);
        break;
      case Product.HOUSE:
        sendHouseProgress(HouseSteps.AUTH);
        break;
    }
  }, []);

  const isSubmitButtomDisabled = !!Object.keys(errors).length;

  const Footer = () => (
    <StyledFooter>
      <Button
        variant="primary"
        label={t('COMMON:buttons.continue') || ''}
        onClick={submitPage}
        disabled={isSubmitButtomDisabled}
        {...addTestAttribute('personalInfo.button.submit')}
      />
      {!agentLogin &&
        !locationState?.isUpdateProfile &&
        isPDConsentAlternativeVersion && (
          <SiteAgreements order="0">
            <Trans
              values={{ href: USER_PERSONAL_DATA_AGREEMENTS }}
              components={{ a: <a /> }}
            >
              {t('AUTH:hints.clickContinueAgreePersonalData')}
            </Trans>
          </SiteAgreements>
        )}
    </StyledFooter>
  );

  if (!!registrationError || !!updateProfileError) {
    return <GlobalErrorInfo retryHandler={handleRefetch} />;
  }

  if (isFeatureFlagsLoading || isRegistrationLoading || updateProfileLoading)
    return <Skeleton />;

  return (
    <BaseLayout>
      <AuthPageWrapper>
        <FullWidthWrapper>
          <AuthPageTitle
            mb={selectedProduct === Product.SPORT ? 16 : 0}
            mbMobile={selectedProduct === Product.SPORT ? 16 : 0}
          >
            {selectedProduct === Product.SPORT
              ? t('AUTH:headers.buyerDetails')
              : t('AUTH:headers.letsGetAcquainted')}
          </AuthPageTitle>
          {selectedProduct === Product.SPORT ? (
            <SectionContainer>
              <Alert
                type="outlined"
                icon={<Age width={24} color="currentColor" />}
                description={
                  <PreLineText>
                    <Trans components={{ BoldText: <BoldText /> }}>
                      {t('AUTH:hints.buyerAge')}
                    </Trans>
                  </PreLineText>
                }
              />
            </SectionContainer>
          ) : (
            <AuthSub>
              {
                // TODO: зависимость от роута необходимо переделать на зависимость от варианта реализации авторизации.
                // Альтернативный текст необходим для авторизации после экрана с ценой. Задача 6103.
                authSubtitle
              }
            </AuthSub>
          )}
          <StyledAdaptiveColumns>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={
                    errors.lastName?.message && t(errors.lastName.message)
                  }
                  testId="personalInfo.lastName.error"
                >
                  <StyledDadataFioSuggestions
                    filterParts={[FilterParts.SURNAME]}
                    onChange={(data) =>
                      setValue(field.name, data?.value || field.value)
                    }
                    defaultQuery={field.value}
                    uid="dadata-lastName"
                    value={getDadataValue(field.value)}
                    inputProps={{
                      ...field,
                      label: t('COMMON:labels.lastName') || '',
                      error: !!errors.lastName,
                      onKeyDown: (e) =>
                        e.keyCode === KeyCode.ENTER &&
                        document.getElementsByTagName('input')[1].focus(),
                      disabled: !!locationState?.isUpdateProfile,
                      ...addTestAttribute('personalInfo.lastName'),
                    }}
                  />
                </HelperText>
              )}
            />
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={
                    errors.firstName?.message && t(errors.firstName.message)
                  }
                  testId="personalInfo.firstName.error"
                >
                  <StyledDadataFioSuggestions
                    filterParts={[FilterParts.NAME]}
                    onChange={(data) =>
                      setValue(field.name, data?.value || field.value)
                    }
                    defaultQuery={field.value}
                    uid="dadata-firstName"
                    value={getDadataValue(field.value)}
                    inputProps={{
                      ...field,
                      label: t('COMMON:labels.firstName') || '',
                      error: !!errors.firstName,
                      onKeyDown: (e) =>
                        e.keyCode === KeyCode.ENTER &&
                        document
                          .getElementsByTagName('input')
                          [
                            cachedPersonalInfoData?.useMiddleName ? 4 : 2
                          ].focus(),
                      disabled: !!locationState?.isUpdateProfile,
                      ...addTestAttribute('personalInfo.firstName'),
                    }}
                  />
                </HelperText>
              )}
            />
            <MiddleNameWrapper>
              <FullWidthWrapper>
                <Controller
                  control={control}
                  name="middleName"
                  render={({ field, fieldState }) => (
                    <HelperText
                      status={fieldState.error ? 'error' : 'default'}
                      message={middleNameFieldHint}
                      testId="personalInfo.middleName.error"
                    >
                      <StyledDadataFioSuggestions
                        filterParts={[FilterParts.PATRONYMIC]}
                        onChange={(data) => {
                          setValue(field.name, data?.value || field.value);
                        }}
                        defaultQuery={field.value}
                        uid="dadata-middleName"
                        value={getDadataValue(field.value)}
                        inputProps={{
                          ...field,
                          disabled:
                            cachedPersonalInfoData?.useMiddleName ||
                            !!locationState?.isUpdateProfile,
                          label: t('COMMON:labels.middleName') || '',
                          error: !!errors.middleName,
                          onKeyDown: (e) =>
                            e.keyCode === KeyCode.ENTER &&
                            document.getElementsByTagName('input')[4].focus(),
                          ...addTestAttribute('personalInfo.middleName'),
                        }}
                      />
                    </HelperText>
                  )}
                />
              </FullWidthWrapper>
            </MiddleNameWrapper>
            <Controller
              control={control}
              name="birthDay"
              render={({ field: { onChange, onBlur, value }, fieldState }) => {
                return (
                  <HelperText
                    status={fieldState.error ? 'error' : 'default'}
                    message={
                      errors.birthDay?.message
                        ? t(errors.birthDay.message)
                        : selectedProduct === Product.SPORT && (
                            <StyledMessage>
                              <Trans>{t('AUTH:hints.policyValidAge')}</Trans>
                            </StyledMessage>
                          )
                    }
                    testId="personalInfo.birthday.error"
                  >
                    <StyledDatePicker
                      error={!!errors.birthDay}
                      label={t('COMMON:labels.dateOfBirth') || ''}
                      selected={value as any}
                      onBlur={onBlur}
                      showYearDropdown
                      showMonthDropdown
                      showInput
                      onChange={onChange}
                      maxDate={maxDate}
                      popperContainer={CalendarContainer}
                      {...addTestAttribute('personalInfo.birthday')}
                    />
                  </HelperText>
                );
              }}
            />
          </StyledAdaptiveColumns>
          {!agentLogin && !locationState?.isUpdateProfile && (
            <StyledCheckboxContainer>
              {!isPDConsentAlternativeVersion && (
                <Controller
                  control={control}
                  name="userPoliciesCode003"
                  render={({ field: { onChange }, fieldState }) => (
                    <Checkbox
                      checked={
                        cachedPersonalInfoData?.userPoliciesCode003 || false
                      }
                      label={
                        <Trans
                          values={{ href: USER_PERSONAL_DATA_AGREEMENTS }}
                          components={{ a: <a /> }}
                        >
                          {t('AUTH:hints.agreePersonalData')}
                        </Trans>
                      }
                      name="userPoliciesCode003"
                      onChange={onChange}
                      message={
                        errors.userPoliciesCode003?.message &&
                        (t(errors.userPoliciesCode003.message) || '')
                      }
                      status={fieldState.error && 'error'}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name="userPoliciesCode002"
                render={({ field: { onChange }, fieldState }) => (
                  <Checkbox
                    checked={
                      cachedPersonalInfoData?.userPoliciesCode002 || false
                    }
                    label={
                      <Trans
                        values={{ href: USER_RECL_AGREEMENTS_URL }}
                        components={{ a: <StyledLink /> }}
                      >
                        {t('AUTH:hints.agreeReceiveSpecialOffers')}
                      </Trans>
                    }
                    name="userPoliciesCode002"
                    onChange={onChange}
                    message={
                      errors.userPoliciesCode002?.message &&
                      (t(errors.userPoliciesCode002.message) || '')
                    }
                    status={fieldState.error && 'error'}
                  />
                )}
              />
            </StyledCheckboxContainer>
          )}
          <Footer />
        </FullWidthWrapper>
      </AuthPageWrapper>
    </BaseLayout>
  );
};
